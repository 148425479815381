import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import HorizontalPackList from '../../../components/HorizontalPackList';
import Banner from '../../../components/Banner';
import ListChart from '../../../components/ListChart';
import FunFactContainer from '../../../components/FunFactContainer';
import { MetaTags } from 'react-meta-tags';
import APIConnection from '../../../api';
import UserAPI from '../../../classes/User';
import SkeletonContainer from '../../../components/SkeletonContainer';

class HomePage extends Component{
    constructor(props){
        super(props);
        this.state = {
            topWeeklyLikedPacks : [],
            topWeeklyPurchasedPacks : [],
            users : [],
        }
    }

    componentDidMount = async () => {
        try{
            const { weekly } = UserAPI.getTops();
            const { message } = await APIConnection.getUsersMostFavorites();
            this.setState({
                users : message.data.slice(0, 10) /* Limit to first 10 */,
                //topWeeklyLikedPacks : weekly.find(obj => obj["action"] === "favorites").packs,
                //topWeeklyPurchasedPacks : weekly.find(obj => obj["action"] === "purchases").packs,
            })
        }catch(err){console.log(err);} 
    }

    render = () => {
        const { topWeeklyLikedPacks, topWeeklyPurchasedPacks, users } = this.state;
        return (
            <div className='page'>
                <MetaTags>
                    <title>Home</title>
                    <meta name="description" content="Stay up to date with today´s trends" />
                    <meta property="og:title" content="Home" />
                </MetaTags>
                {/* Banner */}
                <Banner/>
                {/* Popular this Week */}
                <ListChart title={'Most Active Curators'} timeline={''} data={users}/>
                {/* Latest Releases */}
                {/*<HorizontalPackList title={'Most Liked Packs'} data={topWeeklyLikedPacks} id={'latest-packs'}/>*/}
                {/* Fun Fact */}
                <FunFactContainer/>
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(HomePage);
