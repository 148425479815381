import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logo_grey } from "../../../assets/images";
import { AccountCircle, AccountCircleOutlined, Apps, BubbleChart, FavoriteOutlined, SpaceDashboard } from '@mui/icons-material';
import { ROUTES } from '../../route';
import UrlNavigatorSingleton from '../../classes/UrlNavigator';
import HOC from '../HOC';
import { useNavigate } from 'react-router-dom';
import UserData from '../UserData';

const LeftBar = (props) => {
    const navigate = useNavigate()

    const renderTitle = (icon, title, urlKey) => {
        const isActive = UrlNavigatorSingleton.isInPage(urlKey);
        return (
            <div className='link-container'>
                <Link  
                    to={ROUTES.find(e => e.key == urlKey).path}
                    className={`title-leftBar left-bar-icon ${isActive ? "isActive" : ""}`}>
                    {icon}
                    <h4 className='text-left leftbar-text title' style={{marginLeft : 10}}>{title}</h4>
                </Link>
            </div>

        )
    }

    return (
        <div className='left-bar'>
            <div className="align-center">
                <div className='logo-container'>
                    <img src={logo_grey}/>
                    <div/>
                    <div className='mobile-only'>
                        <UserData/>
                    </div>
                </div>
                <div className='container-box-left-bar'>
                    {renderTitle(<Apps className='icon-leftBar' style={{width : 20}}/>, "Home", "home")}
                    {renderTitle(<BubbleChart className='icon-leftBar' style={{width : 20}}/>, "Search", "search")}
                    {renderTitle(<SpaceDashboard className='icon-leftBar'  style={{width : 20}}/>, "My Library", "library")}
                    {renderTitle(<FavoriteOutlined className='icon-leftBar'  style={{width : 20}}/>, "Likes", "favorites")}
                    {renderTitle(<AccountCircle className='icon-leftBar'  style={{width : 20}}/>, "Account", "account")}
                </div>
            </div>
        </div>
    )
    
}

function mapStateToProps(state) {
    return {
        user :  state.user,
        router : state.router
    };
  }



export default connect(mapStateToProps)(LeftBar);
